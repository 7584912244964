.App {
  text-align: center;
}

.container-fluid {
  background-color: #ececec;
}

.container{
  background-color: #fff;
}

.bottom-logo-container{
  margin-right: 1rem;
  margin-left: 1rem;
  max-height: 5rem;
}

.bottom-container{
  margin-bottom: 1rem !important;
}

.nav-link{
  color: rgba(0,0,0,0.8)!important;
}

.bg-light{
  background-color: #fff!important;
}

.header-logo{
  width: 100%;
}

.footer-logo{
  width: 20%;
}

.home-header-logo{
  min-width: 5%;
  max-width: 8%;
}

.page-container{
  padding-top: 1.5rem;
}

.card{
  margin-bottom: 1rem;
}

.welcomeTitle{
  margin-top: 0.3rem;
  padding-top: 0.3rem;
  padding-left: 1rem;
  text-align: left;
  background: #f1f1f1;
}