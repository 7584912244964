.side-menu-container{
    border-right: 1px solid rgba(0,0,0,.1);
    
}

/*.mainMenuOption{
    border-left: 3px solid rgb(31,209,229);
}

.mainMenuOption:hover{
    border-left: 0px solid rgb(31,209,229);
}*/

.mainMenuOption:after {   
    content: "";
    display: block;
    height: 2px;
    left: 1rem;
    background: rgb(31,209,229);
    position: absolute;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.mainMenuOption:hover:after { 
    width: 90%; 
    left: 1rem; 
}

.mainMenuOptionAlt:after {   
    content: "";
    display: block;
    height: 2px;
    left: 1rem;
    background: rgb(0,102,179);
    position: absolute;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.mainMenuOptionAlt:hover:after { 
    width: 90%; 
    left: 1rem; 
}

.guest-container{
    margin-top: 4rem;
    margin-bottom: 14rem;
}